import { Box, Button, Card, Typography } from '@mui/material/';
import {
  EntityRefLink,
  EntityTable,
  catalogApiRef,
  useEntity,
} from '@backstage/plugin-catalog-react';
import { useEffect, useState } from 'react';

import AddLinkIcon from '@mui/icons-material/AddLink';
import { BSSystem } from '@internal/backstage-plugin-models-common';
import { DKTCustomDialog } from '@internal/common';
import { Entity } from '@backstage/catalog-model';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { TableColumn } from '@backstage/core-components';
import { makeStyles } from '@mui/styles';
import { useApi } from '@backstage/core-plugin-api';

const useStyles = makeStyles(theme => ({
  cardButton: {
    borderRadius: '5px !important',
    textTransform: 'capitalize',
    marginLeft: '15px !important',
    padding: '5px 15px',
    ['&:hover']: {
      backgroundColor: '#E1E3F5 !important',
      color: theme.palette.primary.main + ' !important',
    },
  },
}));

export function CustomLinkedComponentCard() {
  const { entity } = useEntity<BSSystem>();
  const [operation, setOperation] = useState<string>('');
  const [contentText, setContentText] = useState<string>('');
  const [modalTitle, setModalTitle] = useState<string>('');
  const [componentToUnlink, setComponentToUnlink] = useState<string>();
  const [kind, setKind] = useState<string>('systems');
  const classes = useStyles();
  const buttonClass = classes.cardButton;
  const catalogApi = useApi(catalogApiRef);
  const [linkDialog, setLinkDialog] = useState(false);
  const [actualSystem, setActualSystem] = useState<Entity>();
  const [linkedComponents, setLinkedComponents] = useState<Entity[]>([]);
  const retrieveDependsOnComponentsInfo = async (dependsOn: string[]) => {
    const { items } = await catalogApi.getEntities({
      filter: {
        kind: 'Component',
        'metadata.name': dependsOn,
      },
    });

    setLinkedComponents(items);
  };
  const refreshEntity = async () => {
    await catalogApi.refreshEntity(
      `system:default/${actualSystem?.metadata.name}`,
    );
    await new Promise(resolve => setTimeout(resolve, 1000));
    const updatedEntity = await catalogApi.getEntityByRef(
      `system:default/${actualSystem?.metadata.name}`,
    );

    updatedEntity && setActualSystem(updatedEntity);
    if (
      Array.isArray(updatedEntity?.spec?.dependsOn) &&
      updatedEntity.spec.dependsOn.length
    ) {
      retrieveDependsOnComponentsInfo(updatedEntity.spec.dependsOn as string[]);
    } else {
      setLinkedComponents([]);
    }

    setLinkDialog(false);
  };

  const componentEntityColumns: TableColumn<Entity>[] = [
    {
      title: 'Name',
      field: 'name',
      highlight: true,
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle() {
        return {
          textAlign: 'center',
        };
      },
      render: (rowData: Entity) => (
        <Box>
          <EntityRefLink entityRef={rowData} target="_blank" hideIcon />
        </Box>
      ),
    },
    {
      title: 'Type',
      field: 'type',
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle() {
        return {
          textAlign: 'center',
        };
      },
      render: (rowData: Entity) => (
        <Box>
          <Typography variant="body2">{String(rowData.spec?.type)}</Typography>
        </Box>
      ),
    },
    {
      title: 'Lifecycle',
      field: 'lifecycle',
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle() {
        return {
          textAlign: 'center',
        };
      },
      render: (rowData: Entity) => (
        <Box>
          <Typography variant="body2">
            {String(rowData.spec?.lifecycle ?? '')}
          </Typography>
        </Box>
      ),
    },
    {
      title: 'Actions',
      field: 'actions',
      sorting: false,
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle() {
        return {
          textAlign: 'center',
        };
      },
      render: (rowData: Entity) => (
        <Box
          onClick={() => {
            setComponentToUnlink(rowData.metadata.name);
            setKind('system');
            setLinkDialog(true);
            setOperation('Unlink');
            setContentText(
              `You are about to unlink the component ${rowData.metadata.title} from the system ${actualSystem?.metadata.title}`,
            );
            setModalTitle('Unlink component');
          }}
          sx={{ cursor: 'pointer' }}
          data-testid="unlink-component"
        >
          <LinkOffIcon color="primary" />
        </Box>
      ),
    },
  ];

  useEffect(() => {
    setActualSystem(entity);
    if (entity?.spec?.dependsOn?.length) {
      retrieveDependsOnComponentsInfo(entity.spec.dependsOn);
    }
  }, [entity]);

  return (
    <Card>
      <Grid display="flex" alignItems="center">
        <Grid display="flex" alignItems="center">
          <Typography variant="h5" component="h2">
            Other system component(s) used
          </Typography>
        </Grid>
        <Grid display="flex" alignItems="center" sx={{ marginLeft: 'auto' }}>
          <Button
            color="primary"
            variant="contained"
            className={buttonClass}
            startIcon={<AddLinkIcon />}
            onClick={() => {
              setKind('system');
              setOperation('Update');
              setLinkDialog(true);
              setContentText(
                `Select a component from the list below and click on “Confirm” to link it to the system ${entity.metadata.title}`,
              );
              setModalTitle('Link an existing component');
            }}
          >
            Link existing components
          </Button>
        </Grid>
      </Grid>
      {linkedComponents?.length > 0 ? (
        <EntityTable
          tableOptions={{
            toolbar: false,
          }}
          title=""
          entities={linkedComponents}
          columns={componentEntityColumns}
        />
      ) : (
        <Typography
          variant="body2"
          align="center"
          sx={{ margin: '2rem', fontWeight: 'bold' }}
          color={'textSecondary'}
        >
          No linked components to display
        </Typography>
      )}
      <DKTCustomDialog
        kind={kind}
        open={linkDialog}
        operation={operation}
        onClose={() => refreshEntity()}
        componentToUnlink={componentToUnlink}
        entity={actualSystem as Entity}
        contentText={contentText}
        modalTitle={modalTitle}
      />
    </Card>
  );
}
