import { CardTab, TabbedCard } from '@backstage/core-components';
import { OpenApiDefinitionWidget } from '@backstage/plugin-api-docs';
import { useEntity } from '@backstage/plugin-catalog-react';
import { ErrorTabbedCard } from './ErrorTabbedCard';
import { Entity } from '@backstage/catalog-model';
import { useOpenapi } from '@internal/common';
import { useOpenAPIStyles } from '../theme';

type OpenApiCardProps = {
  entity?: Entity;
  verticalTabs?: boolean;
};

export function OpenApiCard(props: Readonly<OpenApiCardProps>) {
  const entity = props.entity ?? useEntity().entity;
  const { openapis, error } = useOpenapi(entity.metadata.name);
  const classes = useOpenAPIStyles();

  if (error) {
    return <ErrorTabbedCard error={error.message} />;
  }

  return (
    <TabbedCard {...{ className: classes.openapiCard }}>
      {openapis.map((openapi) => (
        <CardTab label={openapi.openapiName} key={openapi.openapiName}>
          <OpenApiDefinitionWidget definition={openapi.content} />
        </CardTab>
      ))}
    </TabbedCard>
  );
}
