import { FC } from 'react';
import { IconComponent } from '@backstage/core-plugin-api';
import { SidebarItem } from '@backstage/core-components';
import { useUser } from '@internal/common';

interface Props {
  icon: IconComponent;
  to: string;
  text: string;
}

export const AdminButton: FC<Props> = ({ icon, to, text }) => {
  const { isAdmin, isAdminCuj } = useUser();
  return isAdminCuj || isAdmin ? (
    <SidebarItem icon={icon} to={to} text={text} />
  ) : (
    <></>
  );
};
