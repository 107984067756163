import { DKTCustomCTA, DKTEntityLayout } from '@internal/common';
import {
  RELATION_API_CONSUMED_BY,
  RELATION_API_PROVIDED_BY,
} from '@backstage/catalog-model';

import { DktEntityAboutCard } from '../common/DktEntityAboutCard/DktEntityAboutCard';
import { EntityCatalogGraphCard } from '@backstage/plugin-catalog-graph';
import { EntityLayout } from '@backstage/plugin-catalog';
import Grid from '@mui/material/Unstable_Grid2';
import { OpenApiCard } from '../common/OpenapiCard/OpenApiCard';
import { ProvidingOrConsumingComponentsCard } from '@internal/common/src/ui/ProvidingOrConsumingComponentsCard/ProvidingOrConsumingComponentsCard';
import { entityWarningContent } from '../common/EntityWarningContent';

export const apiPage = (
  <DKTEntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}

        <Grid md={12}>
          <DKTCustomCTA kind="api" />
        </Grid>
        <Grid md={6}>
          <DktEntityAboutCard variant="gridItem" />
        </Grid>
        <Grid md={6} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
        <Grid container md={12}>
          <Grid md={6}>
            <ProvidingOrConsumingComponentsCard
              title={'Providers'}
              filterType={RELATION_API_PROVIDED_BY}
              emptyContentMessage={'No component provides this API'}
            />
          </Grid>
          <Grid md={6}>
            <ProvidingOrConsumingComponentsCard
              title={'Consumers'}
              filterType={RELATION_API_CONSUMED_BY}
              emptyContentMessage={'No component consumes this API'}
            />
          </Grid>
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/definition" title="Definition">
      <Grid container spacing={3}>
        <Grid xs={12}>
          <OpenApiCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </DKTEntityLayout>
);
