import { ApiEntity } from '@backstage/catalog-model';
import { OpenApiDefinitionWidget } from '@backstage/plugin-api-docs';
import { useOpenapi } from '@internal/common/src/hooks/use-openapi';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useOpenAPIStyles } from 'app/src/components/catalog/common/theme';
import React, { useEffect } from 'react';

const useStyles = makeStyles(() => ({
  fullHeightDialog: {
    height: 'calc(100% - 64px)',
  },
}));

type TabPanelProps = {
  children?: React.ReactNode;
  index: number;
  value: number;
};

function TabPanel(props: Readonly<TabPanelProps>) {
  const { children, value, index, ...other } = props;
  const classes = useOpenAPIStyles();
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      className={classes.tabContents}
      {...other}
    >
      {value === index && (
        <Box pl={3} pr={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

type ApiDefinitionDialogProps = {
  open: boolean;
  entity: ApiEntity;
  onClose: () => void;
};

export function ApiDefinitionDialog(props: Readonly<ApiDefinitionDialogProps>) {
  const { open, entity, onClose } = props;
  const [activeTab, setActiveTab] = React.useState(0);
  const classes = { ...useStyles(), ...useOpenAPIStyles() };
  let tabIndex = 0;
  let tabPanelIndex = 0;
  const { openapis, error } = useOpenapi(entity.metadata.name);

  useEffect(() => {
    setActiveTab(0);
  }, [open]);

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      open={open}
      onClose={onClose}
      aria-labelledby="api-definition-dialog-title"
      PaperProps={{ className: classes.fullHeightDialog }}
    >
      <DialogTitle id="api-definition-dialog-title" disableTypography>
        <Typography className={classes.title} variant="h1">
          {entity.metadata.title ?? entity.metadata.name}
        </Typography>
      </DialogTitle>
      <DialogContent dividers className={classes.root}>
        {error && (
          <Typography variant="body1" color="error">
            {error.message}
          </Typography>
        )}
        {!error && (
          <>
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={activeTab}
              onChange={(_, newValue) => setActiveTab(newValue)}
              aria-label="API definition options"
              className={classes.tabs}
            >
              {openapis.map(openapi => (
                <Tab
                  key={openapi.openapiName}
                  label={openapi.openapiName}
                  {...a11yProps(tabIndex++)}
                />
              ))}
            </Tabs>
            {openapis.map(openapi => {
              return (
                <TabPanel
                  key={openapi.openapiName}
                  value={activeTab}
                  index={tabPanelIndex++}
                >
                  <OpenApiDefinitionWidget definition={openapi.content} />
                </TabPanel>
              );
            })}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
