import { SvgIcon, SvgIconProps } from '@mui/material/';

export function SmaxIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 542.75 545.95">
      <g>
        <path
          fill="#3643BA"
          style={{ fill: '#3643BA', strokeWidth: 0 }}
          d="M507.75,270c-1.58-70.3-27.47-133.06-82.8-182.17-41.46-36.81-90.59-55.46-146.08-55.18-32.59.16-60.61,12.66-80.71,39.01-24.91,32.66-22.62,78.36,4.32,110.35,14.85,17.63,33.28,31.11,51.4,44.97,19.97,15.28,40.23,30.23,57.82,48.34,11.84,12.18,23.5,24.43,28.9,41.29,10.27,32.04-1.74,62.61-31.1,77.99-1.03.54-2.09,1.03-3.16,1.48-4.42,1.86-8.24.86-11.11-2.87-2.54-3.3-1.73-7.63,1.97-11.13,6.75-6.39,12.42-13.48,14.71-22.71,4.76-19.18-.13-35.7-13.5-50.14-17.18-18.56-37.29-33.68-56.93-49.37-20.29-16.22-40.92-32.04-59.13-50.69-56.05-57.39-41.26-153.95,29.4-191.96C232.35,6.13,254.31.12,277.72,0c99.73-.5,174.23,43.96,225.41,128.28,29.37,48.38,41.76,101.79,39.31,158.1-3.55,81.59-38.14,148.16-101.17,199.83-37.1,30.41-79.71,49.36-127.09,56.36-79.77,11.78-151.77-6.97-214.32-58.41-49.84-40.99-81.84-93.3-94.24-156.82-13.69-70.15-2.42-136.36,33.91-198.06,6.53-11.09,14.82-20.91,24.01-29.94,2.27-2.23,4.71-4.24,7.69-5.46,3.8-1.55,7.51-1.73,10.66,1.37,3.27,3.2,3.43,7.04,1.77,11.06-.5,1.22-1.27,2.34-2.01,3.44-23.4,34.76-39.46,72.53-44.24,114.31-8.84,77.32,7.88,147.7,59.97,207.4,36.03,41.3,80.84,68.35,135.5,76.06,86.32,12.17,159.62-13.85,217.4-79.6,38.68-44.01,56.72-96.36,57.46-157.91Z"
        />
        <path
          fill="#3643BA"
          style={{ fill: '#3643BA', strokeWidth: 0 }}
          d="M273.53,464.1c-35.96-1.01-69.79-9.09-99.61-30.29-7.36-5.23-13.94-11.35-19.46-18.55-1.11-1.44-2.15-2.99-2.91-4.63-2.64-5.68-1.92-10.6,1.83-13.7,3.59-2.98,7.72-2.86,13.24,1.18,11.58,8.48,23.57,16.17,36.99,21.49,38.28,15.16,76.62,17.24,114.95.4,50.18-22.06,68.52-80.68,40.44-127.86-13.86-23.28-32.92-41.69-53.12-59.18-20.13-17.44-41.1-33.96-59.61-53.19-11.83-12.29-22.66-25.3-27.51-42.21-6.91-24.05.37-44.55,15.74-62.96,3.48-4.16,8.58-4.57,12.54-1.71,3.87,2.8,5.01,7.62,2.47,12.45-6.52,12.38-9.49,25.14-5.07,38.92,3.65,11.4,10.67,20.57,18.87,29.01,17.13,17.62,36.93,32.06,56.03,47.36,23.56,18.87,46.06,38.91,63.66,63.79,52.35,74.02,14.86,174.06-73.3,195.51-11.87,2.89-23.92,4.15-36.16,4.17Z"
        />
      </g>
    </SvgIcon>
  );
}
