import {
  configApiRef,
  identityApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { useEffect, useMemo, useState } from 'react';

import { BS_ENT } from '@internal/backstage-plugin-models-common/src/enums';
import { IDPUser } from '@internal/backstage-plugin-models-common/src/schemas';
import { useBackstageApi } from './use-backstage-api';
import { useUserProfile } from '@backstage/plugin-user-settings';

export const useUser = () => {
  const config = useApi(configApiRef);
  const identityApi = useApi(identityApiRef);

  const { backstageFetch } = useBackstageApi();
  const { displayName } = useUserProfile();

  const userProfileUid = useMemo(
    () => displayName.split('/').pop(),
    [displayName],
  );

  const [user, setUser] = useState<IDPUser>();
  const [userRights, setUserRights] = useState<string[]>([]);

  useEffect(() => {
    identityApi.getBackstageIdentity().then(identity => {
      setUserRights(identity.ownershipEntityRefs);
    });
  }, [identityApi]);

  useEffect(() => {
    if (userProfileUid) {
      const fetchUser = async () => {
        try {
          const idpUser = await backstageFetch<IDPUser>(
            `${config.getString(
              'backend.baseUrl',
            )}/api/catalog/users/${userProfileUid}`,
          );
          setUser(idpUser);
        } catch (error) {
          console.error('Failed to fetch user:', error);
        }
      };
      fetchUser();
    }
  }, [userProfileUid]);

  const isAdmin = useMemo(
    () => userRights.includes(BS_ENT.ADMIN),
    [userRights],
  );

  const isAdminCuj = useMemo(
    () => userRights.includes(BS_ENT.ADMIN_CUJ),
    [userRights],
  );

  const isInSuppportGroup = (id: string) => {
    const sanitzedId = id.replace('group:default/', '');
    return user?.support_groups?.includes(sanitzedId) ?? false;
  };
  return { user, isAdmin, isAdminCuj, isInSuppportGroup };
};
