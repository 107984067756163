import { Button, Grid2 } from '@mui/material';
import {
  EntityDisplayName,
  EntityRefLink,
  catalogApiRef,
  useEntity,
  useRelatedEntities,
} from '@backstage/plugin-catalog-react';

import { ApiEntity } from '@backstage/catalog-model';
import { ApisTable } from '../common/ApisTable';
import { RegisterApisDialog } from './RegisterApisDialog';
import { SearchContextProvider } from '@backstage/plugin-search-react';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import { useApi } from '@backstage/core-plugin-api';
import { useState } from 'react';
import { ApiDefinitionDialog } from '../common/ApiDefinitionDialog';

export const ProvidedApisCard = () => {
  const [openRegisterApis, setOpenRegisterApis] = useState(false);
  const [openApiDefinition, setOpenApiDefinition] = useState(false);

  const { entity } = useEntity();
  const [currentEntity, setCurrentEntity] = useState(entity);
  const catalogApi = useApi(catalogApiRef);
  const { entities } = useRelatedEntities(currentEntity, {
    type: 'providesApi',
    kind: 'api',
  });
  const [selectedApi, setSelectedApi] = useState<ApiEntity>();

  const onOpenApidDefinitionDialog = (api: ApiEntity) => {
    setOpenApiDefinition(true);
    setSelectedApi(api);
  };

  return (
    <SearchContextProvider
      initialState={{
        term: '',
        filters: { kind: 'API' },
        types: ['software-catalog'],
      }}
    >
      <Grid2 container>
        <Grid2 size={12} sx={{ position: 'relative' }}>
          <Button
            variant="contained"
            color={undefined}
            onClick={() => setOpenRegisterApis(true)}
            data-testid="add-api-btn"
            sx={{
              backgroundColor: '#1DB954!important',
              width: '210px',
              zIndex: 5,
              position: 'absolute !important',
              right: 20,
              top: 20,
            }}
          >
            Manage API(s)
          </Button>
        </Grid2>
        <Grid2 size={12} sx={{ position: 'relative' }}>
          <ApisTable
            emptyContentMessage="This component does not provide any APIs."
            entities={entities as ApiEntity[]}
            columns={[
              {
                title: 'Name',
                render: (row: any) => {
                  const entityRef = {
                    kind: 'API',
                    namespace: 'default',
                    name: row.metadata.name,
                  };
                  return row.metadata.name ? (
                    <EntityRefLink entityRef={entityRef}>
                      <EntityDisplayName
                        hideIcon={true}
                        entityRef={entityRef}
                      />
                    </EntityRefLink>
                  ) : (
                    <div>-</div>
                  );
                },
              },
              {
                title: 'Owner',
                render: (row: any) =>
                  !row.spec.owner.includes('null') ? (
                    <EntityRefLink
                      entityRef={row.spec.owner}
                      defaultKind="user"
                    />
                  ) : (
                    <div>-</div>
                  ),
              },
              { title: 'Type', field: 'spec.type' },
              { title: 'Lifecycle', field: 'spec.lifecycle' },
              { title: 'Description', field: 'metadata.description' },
              {
                title: 'API Definition',
                render: (row: any) => {
                  return (
                    <Button
                      onClick={() => onOpenApidDefinitionDialog(row)}
                      data-testid="api-definition"
                    >
                      <WysiwygIcon color="inherit" />
                    </Button>
                  );
                },
              },
            ]}
          />
        </Grid2>
      </Grid2>
      {selectedApi && (
        <ApiDefinitionDialog
          open={openApiDefinition}
          onClose={() => setOpenApiDefinition(false)}
          entity={{
            apiVersion: 'backstage.io/v1alpha1',
            kind: selectedApi.kind,
            metadata: {
              name: selectedApi.metadata.name,
              title: selectedApi.metadata.title,
              description: selectedApi.metadata.description,
              namespace: selectedApi.metadata.namespace,
            },
            spec: {
              owner: selectedApi.spec.owner,
              system: selectedApi.spec.system,
              lifecycle: selectedApi.spec.lifecycle,
              type: selectedApi.spec.type,
              definition: selectedApi.spec.definition,
            },
          }}
        />
      )}
      <RegisterApisDialog
        open={openRegisterApis}
        onClose={async () => {
          await catalogApi.refreshEntity(
            `component:default/${entity.metadata.name}`,
          );
          // Ensuring that the entity has been refreshed with a delay
          await new Promise(resolve => setTimeout(resolve, 1000));
          const updatedEntity = await catalogApi.getEntityByRef(
            `component:default/${entity.metadata.name}`,
          );
          updatedEntity && setCurrentEntity(updatedEntity);
          setOpenRegisterApis(false);
        }}
      />
    </SearchContextProvider>
  );
};
