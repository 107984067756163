import { Box, Button, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';
import { SlackIcon, SmaxIcon, WikiIcon } from '@internal/common';

interface Props {
  title: string | ReactNode;
  subtitle?: string;
}

export const Header: FC<Props> = ({ title, subtitle }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: '#FFFFFF',
        paddingX: '1rem',
      }}
    >
      <Box
        sx={{
          color: '#3643BA',
          my: '1rem',
        }}
      >
        <Typography
          variant="caption"
          gutterBottom
          sx={{ display: 'block', mb: '0' }}
        >
          {subtitle}
        </Typography>
        <Typography
          variant="h1"
          sx={{
            fontSize: '1.5rem',
            margin: '0',
            padding: '0',
          }}
        >
          {title}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'center',
          width: '450px',
          gap: '.25rem',
        }}
      >
        <Button
          variant="outlined"
          size="small"
          sx={{
            backgroundColor: '#FFFFFF',
            borderRadius: 100,
            fontSize: '.75rem',
            border: '1px solid white',
            paddingX: '1rem',
            minWidth: '2rem',
          }}
          href="https://decathlon.atlassian.net/wiki/x/bARlHg"
          target="_blank"
        >
          <WikiIcon
            style={{
              height: '1.25rem',
              width: '1.25rem',
            }}
          />
        </Button>
        <Button
          variant="outlined"
          size="small"
          sx={{
            backgroundColor: '#FFFFFF',
            borderRadius: 100,
            fontSize: '.75rem',
            border: '1px solid white',
            paddingX: '1rem',
            minWidth: '2rem',
          }}
          href="https://support.decathlon.net/saw/ess/offeringPage/129649339"
          target="_blank"
        >
          <SmaxIcon
            style={{
              height: '1.25rem',
              width: '1.25rem',
            }}
          />
        </Button>
        <Button
          variant="outlined"
          size="small"
          sx={{
            backgroundColor: '#FFFFFF',
            borderRadius: 100,
            fontSize: '.75rem',
            border: '1px solid white',
            paddingX: '1rem',
            minWidth: '2rem',
          }}
          href="https://app.slack.com/client/T6U6X43D5/C06562N4Q6P"
          target="_blank"
        >
          <SlackIcon
            style={{
              height: '1.25rem',
              width: '1.25rem',
            }}
          />
        </Button>
      </Box>
    </Box>
  );
};
