import { Box, Button, IconButton, Tooltip } from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';
import {
  InspectEntityDialog,
  useEntity,
  useStarredEntities,
} from '@backstage/plugin-catalog-react';
import { useNotification, useRefresh, useUser } from '@internal/common';

import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BugReportIcon from '@mui/icons-material/BugReportOutlined';
import { DKTCustomDialog } from '../DKTCustomDialog/DKTCustomDialog';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/EditOutlined';
import { Entity } from '@backstage/catalog-model';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import Grid from '@mui/material/Unstable_Grid2';
import { LinkButton } from '@backstage/core-components';
import { Refresh } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

interface DKTCustomCTAProps {
  kind: string;
}

const useStyles = makeStyles(theme => ({
  cardButton: {
    borderRadius: '5px !important',
    textTransform: 'capitalize',
    marginLeft: '15px !important',
    textDecoration: 'none !important',
    padding: '5px 15px',
    ['&:hover']: {
      backgroundColor: '#E1E3F5 !important',
      color: theme.palette.primary.main + ' !important',
    },
  },
  closeButton: {
    color: theme.palette.grey[500],
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
}));

export const DKTCustomCTA: FC<DKTCustomCTAProps> = ({ kind }) => {
  const { entity } = useEntity<Entity>();
  const [synchroniseUrl, setSynchroniseUrl] = useState<string>('');
  const { synchroniseEntity } = useRefresh(entity.metadata.name, {
    synchroniseUrl,
  });
  const classes = useStyles();
  const buttonClass = classes.cardButton;
  const { isStarredEntity, toggleStarredEntity } = useStarredEntities();
  const [inspectOpen, setInspectOpen] = useState(false);
  const [urlAlertState, setUrlAlertState] = useState(false);
  const { setNotification } = useNotification();
  const [openDialog, setOpenDialog] = useState(false);
  const [disableMessage, setDisableMessage] = useState('');
  const isNonCTAKind = ['group', 'user', 'api'].includes(kind);
  const { user, isAdmin, isAdminCuj, isInSuppportGroup } = useUser();
  const entityBaseUrl = `${kind}:default/${entity.metadata.name}`;
  const editUrl = `/register/${kind}s/${entity.metadata.name}/${
    kind === 'system' ? 'edit' : ''
  }`;

  const isEditDisabled = useMemo(() => {
    switch (true) {
      case isAdmin:
        return false;
      case entity.kind === 'UserJourney' && !isAdminCuj:
        setDisableMessage('You need to be an admin or a user journey admin');
        return true;
      case entity?.spec?.supportGroup &&
        !isInSuppportGroup(entity?.spec?.supportGroup as string):
        setDisableMessage('You need to be in the support group');
        return true;
      default:
        return false;
    }
  }, [entity, user]);

  useEffect(() => {
    setSynchroniseUrl(`/api/catalog/${kind}s/`);
  }, [kind]);

  useEffect(() => {
    if (urlAlertState) {
      setNotification({
        message: 'URL copied to clipboard',
        severity: 'success',
      });
      setUrlAlertState(false);
    }
  }, [urlAlertState, setNotification]);

  return (
    <Grid xs={12} md={12}>
      <Box sx={{ position: 'relative' }}>
        <InspectEntityDialog
          open={inspectOpen}
          onClose={() => setInspectOpen(false)}
          entity={entity}
        />
        <Box
          sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}
        >
          <Tooltip title={'Bookmark entity'}>
            <IconButton
              color="primary"
              onClick={() => {
                toggleStarredEntity(entityBaseUrl);
              }}
            >
              {isStarredEntity(entityBaseUrl) ? (
                <BookmarkIcon
                  data-testid="entity-bookmark-icon"
                  sx={{
                    color: '#FF9800',
                  }}
                />
              ) : (
                <BookmarkBorderIcon />
              )}
            </IconButton>
          </Tooltip>
          <Tooltip title={'Copy entity URL'}>
            <IconButton
              color="primary"
              sx={{ marginLeft: '15px' }}
              onClick={() => {
                setUrlAlertState(true);
                navigator.clipboard.writeText(window.location.href);
              }}
            >
              <FileCopyIcon color="primary" />
            </IconButton>
          </Tooltip>
          <Tooltip title={'Inspect entity'}>
            <IconButton
              sx={{ marginLeft: '15px' }}
              onClick={() => setInspectOpen(true)}
            >
              <BugReportIcon color={`${isAdmin ? 'primary' : 'disabled'}`} />
            </IconButton>
          </Tooltip>
          {!isNonCTAKind && (
            <>
              {isAdmin ? (
                <Tooltip title={`Refresh ${kind}`}>
                  <IconButton
                    sx={{ marginLeft: '15px' }}
                    onClick={synchroniseEntity}
                  >
                    <Refresh color={`${isAdmin ? 'primary' : 'disabled'}`} />
                  </IconButton>
                </Tooltip>
              ) : null}
              {isEditDisabled ? (
                <Tooltip title={disableMessage}>
                  {/* This is a hack to make the tooltip work enven if button is disabled */}
                  <span>
                    <LinkButton
                      disabled={isEditDisabled}
                      to={editUrl}
                      className={buttonClass}
                      size="large"
                      variant="contained"
                      color="primary"
                    >
                      <EditIcon
                        fontSize="inherit"
                        sx={{ marginRight: '5px', fontSize: '24px' }}
                      />
                      Edit
                    </LinkButton>
                  </span>
                </Tooltip>
              ) : (
                <LinkButton
                  disabled={isEditDisabled}
                  to={editUrl}
                  className={buttonClass}
                  size="large"
                  variant="contained"
                  color="primary"
                >
                  <EditIcon
                    fontSize="inherit"
                    sx={{ marginRight: '5px', fontSize: '24px' }}
                  />
                  Edit
                </LinkButton>
              )}

              {isAdmin ? (
                <Button
                  startIcon={
                    <DeleteOutlineIcon
                      fontSize="inherit"
                      sx={{ marginRight: '5px', fontSize: '24px' }}
                    />
                  }
                  className={buttonClass}
                  size="large"
                  variant="contained"
                  color="error"
                  onClick={() => setOpenDialog(true)}
                  disabled={!isAdmin}
                >
                  Delete
                </Button>
              ) : null}
            </>
          )}
        </Box>

        <DKTCustomDialog
          open={openDialog}
          entity={entity}
          onClose={() => setOpenDialog(false)}
          kind={kind}
          redirect={true}
          operation="Delete"
          contentText={`This action cannot be undone. Make sure you want to delete this ${kind}?`}
          modalTitle={`Are you sure you want to delete this ${kind}?`}
        />
      </Box>
    </Grid>
  );
};
