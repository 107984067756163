import {
  CustomComponentCard,
  CustomLinkedComponentCard,
  CustomSystemAboutCard,
  TechPerformanceCard,
} from '@internal/backstage-plugin-custom-system-overview';
import { DKTCustomCTA, DKTEntityLayout } from '@internal/common';
import {
  Direction,
  EntityCatalogGraphCard,
} from '@backstage/plugin-catalog-graph';
import {
  RELATION_API_CONSUMED_BY,
  RELATION_API_PROVIDED_BY,
  RELATION_CONSUMES_API,
  RELATION_DEPENDENCY_OF,
  RELATION_DEPENDS_ON,
  RELATION_HAS_PART,
  RELATION_PART_OF,
  RELATION_PROVIDES_API,
} from '@backstage/catalog-model';

import { EntityLayout } from '@backstage/plugin-catalog';
import Grid from '@mui/material/Unstable_Grid2';

export const SystemPage = () => {
  return (
    <DKTEntityLayout>
      <EntityLayout.Route path="/" title="Overview">
        <Grid container spacing={3} alignItems="stretch">
          <Grid md={12}>
            <DKTCustomCTA kind="system" />
          </Grid>
          <Grid md={6}>
            <CustomSystemAboutCard />
          </Grid>
          <Grid md={6}>
            <TechPerformanceCard />
          </Grid>
          <Grid md={6}>
            <CustomComponentCard />
          </Grid>
          <Grid md={6}>
            <CustomLinkedComponentCard />
          </Grid>
        </Grid>
      </EntityLayout.Route>
      <EntityLayout.Route path="/diagram" title="Diagram">
        <EntityCatalogGraphCard
          variant="gridItem"
          direction={Direction.TOP_BOTTOM}
          title="System Diagram"
          height={700}
          relations={[
            RELATION_PART_OF,
            RELATION_HAS_PART,
            RELATION_API_CONSUMED_BY,
            RELATION_API_PROVIDED_BY,
            RELATION_CONSUMES_API,
            RELATION_PROVIDES_API,
            RELATION_DEPENDENCY_OF,
            RELATION_DEPENDS_ON,
          ]}
          unidirectional={false}
        />
      </EntityLayout.Route>
    </DKTEntityLayout>
  );
};
