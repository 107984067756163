import {
  SearchBar,
  SearchPagination,
  SearchResult,
} from '@backstage/plugin-search-react';

import { Content } from '@backstage/core-components';
import Grid2 from '@mui/material/Unstable_Grid2';
import { Header } from '@internal/common';
import { SearchResultItem } from '../../components/SearchResult';

export function SearchPage() {
  return (
    <div>
      <Header title="Search" />
      <Content>
        <Grid2 container direction="row">
          <Grid2 xs={12}>
            <SearchBar
              style={{
                backgroundColor: 'white',
              }}
            />
          </Grid2>
          <Grid2 xs={12}>
            <SearchResult>
              {({ results }) => {
                return (
                  <>
                    {results.map((result, idx) => (
                      <SearchResultItem
                        key={`${result?.document?.title}${idx}`}
                        result={result}
                      />
                    ))}
                  </>
                );
              }}
            </SearchResult>
            <SearchPagination />
          </Grid2>
        </Grid2>
      </Content>
    </div>
  );
}
