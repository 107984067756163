import { IconComponent, useApp } from '@backstage/core-plugin-api';

import { IconLinkVertical } from './IconLinkVertical';
import PublicIcon from '@mui/icons-material/Public';
import SmaxIcon from 'app/public/smax-icon.png';

interface Props {
  iconName: string;
}

export function IconGenerator({ iconName }: Readonly<Props>) {
  const app = useApp();
  const iconResolver = (key?: string): IconComponent =>
    key
      ? app.getSystemIcon(key) ?? (PublicIcon as IconComponent)
      : (PublicIcon as IconComponent);

  const Icon = iconResolver(iconName.toLowerCase());
  const isSmax = iconName === 'smax' || iconName === 'Smax';

  return (
    <div data-testid={`${iconName}`}>
      <IconLinkVertical
        icon={
          isSmax ? (
            <img src={SmaxIcon} alt="smax icon" width={23} height={23} />
          ) : (
            <Icon fontSize="small" />
          )
        }
        name={iconName}
      />
    </div>
  );
}
