import { Box, Card, Typography } from '@mui/material/';
import {
  ComponentEntity,
  Entity,
  RELATION_HAS_PART,
} from '@backstage/catalog-model';
import {
  EntityTable,
  catalogApiRef,
  useEntity,
} from '@backstage/plugin-catalog-react';
import { LinkButton, TableColumn } from '@backstage/core-components';
import { useEffect, useState } from 'react';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import { BSSystem } from '@internal/backstage-plugin-models-common';
import { DKTCustomDialog } from '@internal/common';
import DeleteIcon from '@mui/icons-material/Delete';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { RelatedEntitiesCard } from '@backstage/plugin-catalog';
import { makeStyles } from '@mui/styles';
import { useApi } from '@backstage/core-plugin-api';

const componentEntityHelpLink = '#';

const asComponentEntities = (entities: Entity[]): ComponentEntity[] =>
  entities as ComponentEntity[];

export function CustomComponentCard() {
  const { entity: system } = useEntity<BSSystem>();
  const [deleteDialog, setDeleteDialog] = useState(false);
  const componentEntityColumns: TableColumn<ComponentEntity>[] = [
    EntityTable.columns.createEntityRefColumn({ defaultKind: 'component' }),
    EntityTable.columns.createSpecTypeColumn(),
    EntityTable.columns.createSpecLifecycleColumn(),
    {
      title: 'Actions',
      sorting: false,
      field: 'actions',
      render: (rowData: ComponentEntity) => (
        <Box
          onClick={() => {
            setDeleteDialog(true);
            setEntityToDelete(rowData);
          }}
          sx={{ cursor: 'pointer' }}
          data-testid="delete-component"
        >
          <DeleteIcon color="primary" />
        </Box>
      ),
    },
  ];
  const [entityToDelete, setEntityToDelete] = useState<Entity>();
  const useStyles = makeStyles(theme => ({
    cardButton: {
      borderRadius: '5px !important',
      textTransform: 'capitalize',
      marginLeft: '15px !important',
      padding: '5px 15px',
      ['&:hover']: {
        backgroundColor: '#E1E3F5 !important',
        color: theme.palette.primary.main + ' !important',
      },
    },
  }));

  const classes = useStyles();
  const buttonClass = classes.cardButton;
  const catalogApi = useApi(catalogApiRef);

  const [actualSystem, setActualSystem] = useState<Entity>();
  useEffect(() => {
    setActualSystem(system);
  }, []);
  const hasComponents = actualSystem?.relations?.some(
    relation =>
      relation.type === RELATION_HAS_PART &&
      relation.targetRef.includes('component'),
  );

  const refreshEntity = async () => {
    setDeleteDialog(false);
    await catalogApi.refreshEntity(
      `system:default/${actualSystem?.metadata.name}`,
    );
    await new Promise(resolve => setTimeout(resolve, 1000));
    const updatedEntity = await catalogApi.getEntityByRef(
      `system:default/${actualSystem?.metadata.name}`,
    );

    updatedEntity && setActualSystem(updatedEntity);
  };

  return (
    <Card>
      <Grid display="flex" alignItems="center">
        <Grid display="flex" alignItems="center">
          <Typography variant="h5" component="h2">
            System component(s)
          </Typography>
        </Grid>
        <Grid display="flex" alignItems="center" sx={{ marginLeft: 'auto' }}>
          <LinkButton
            to={`/register/systems/${system.metadata.name}/add-component`}
            state={{ system: system }}
            color="primary"
            variant="contained"
            className={buttonClass}
          >
            <AddCircleIcon style={{ marginRight: 5 }} />
            Add components
          </LinkButton>
        </Grid>
      </Grid>
      {hasComponents ? (
        <RelatedEntitiesCard
          variant="gridItem"
          title={'Components'} // title is normally of type string but the actual use allows any kind of JSX component
          entityKind="Component"
          relationType={RELATION_HAS_PART}
          columns={componentEntityColumns}
          emptyMessage="No component is part of this system"
          emptyHelpLink={componentEntityHelpLink}
          asRenderableEntities={asComponentEntities}
          tableOptions={{
            showTitle: false,
            toolbar: false,
            headerStyle: {
              textAlign: 'center',
            },
          }}
        />
      ) : (
        <Typography
          variant="body2"
          align="center"
          sx={{ margin: '2rem', fontWeight: 'bold' }}
          color={'textSecondary'}
        >
          No components to display
        </Typography>
      )}

      <DKTCustomDialog
        kind="component"
        open={deleteDialog}
        onClose={() => refreshEntity()}
        entity={entityToDelete!}
        redirect={false}
        operation="Delete"
        contentText="This action cannot be undone. Are you sure you want to delete this component?"
        modalTitle="Are you sure you want to delete this component?"
      />
    </Card>
  );
}
