import {
  createBaseThemeOptions,
  createUnifiedTheme,
  palettes,
} from '@backstage/theme';

import { green } from '@mui/material/colors';

const brandColor = '#3643BA';
const whiteColor = '#FFFFFF';
const errorColor = '#ea6767';

export const customTheme = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.light,
      elite: {
        main: '#C5FCEB',
        contrastText: '#5ABB9D',
      },
      high: {
        main: '#ACE8B3',
        contrastText: '#23A942',
      },
      medium: {
        main: '#FDD3BD',
        contrastText: '#FF600A',
      },
      low: {
        main: '#FFB3B6',
        contrastText: '#E3262F',
      },
      nd: {
        main: '#EEEEEE',
        contrastText: '#FFFFFF',
      },
      dktBlue: {
        main: brandColor,
      },
      primary: {
        main: brandColor,
        contrastText: whiteColor,
      },
      background: {
        default: '#3643ba10',
        paper: 'white',
        error: {
          main: errorColor,
        },
      },
    },
  }),
  components: {
    BackstageSidebar: {
      styleOverrides: {
        drawer: () => ({
          background: `${brandColor}`,
          width: 'auto!important',
        }),
        drawerOpen: () => ({
          background: `${brandColor}`,
          width: 'auto!important',
        }),
      },
    },
    BackstageSidebarItem: {
      styleOverrides: {
        selected: () => ({
          color: '#7AFFA6!important',
        }),
        root: () => ({
          color: `${whiteColor}`,
        }),
        highlightable: () => ({
          '&:hover': {
            backgroundColor: '#7AFFA6!important',
            color: `${brandColor}`,
          },
        }),
        open: () => ({
          '&:hover': {
            backgroundColor: '#7AFFA6!important',
            color: `${brandColor}!important`,
          },
        }),
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          '&.Mui-completed': {
            color: typeof green[500],
          },
          '&.Mui-active': {
            color: '#1769aa',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        alignLeft: {
          textAlign: 'center',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:nth-of-type(odd)': {
            backgroundColor: '#F8F8F8',
          },
        },
      },
    },
    BackstageHeader: {
      styleOverrides: {
        header: {
          backgroundImage: ``,
          background: '#FFFFFF',
          boxShadow: 'none',
          border: '1px solid #eeeeee',
        },
        title: {
          color: `${brandColor}`,
          textAlign: 'center',
        },
        type: {
          color: `${brandColor}`,
          textAlign: 'center',
        },
        subtitle: {
          color: `${brandColor}`,
        },
      },
    },
    BackstageHeaderLabel: {
      styleOverrides: {
        value: {
          color: `${brandColor}`,
        },
        label: {
          color: `${brandColor}`,
        },
      },
    },
    PluginCatalogEntityContextMenu: {
      styleOverrides: {
        button: {
          color: `${brandColor}`,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        label: {
          padding: 0,
          marginLeft: '.25rem',
          marginRight: '.25rem',
          fontWeight: 'bold',
        },
        deleteIcon: {
          fill: 'white',
          color: 'white',
          marginRight: '0',
          '&:hover': {
            opacity: 0.75,
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          boxShadow: 'none',
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          boxShadow: '0px 2px 6px -1px #D6D6D6',
        },
      },
    },
  },
});
