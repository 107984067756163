import { makeStyles } from '@material-ui/core';

export const useOpenAPIStyles = makeStyles((theme) => ({
  openapiCard: {
    width: '100%',
    height: '100%',
  },
  root: {
    display: 'flex',
    flexGrow: 1,
    width: '100%',
    height: '100%',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    flexShrink: 0,
  },
  tabContents: {
    flexGrow: 1,
    overflowX: 'auto',
  },
  title: {
    color: theme.palette.text.primary,
    wordBreak: 'break-word',
    fontSize: theme.typography.h3.fontSize,
    marginBottom: 0,
  },
  type: {
    textTransform: 'uppercase',
    fontSize: 11,
    opacity: 0.8,
    marginBottom: theme.spacing(1),
    color: theme.palette.text.primary,
  },
}));
