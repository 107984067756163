import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { useBackstageApi, useNotification } from '@internal/common';
import { useEffect, useState } from 'react';

import { BSSystem } from '@internal/backstage-plugin-models-common';
import { Entity } from '@backstage/catalog-model';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { LoadingButton } from '@mui/lab';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { useNavigate } from 'react-router';

const CustomDialog = styled(Dialog)(({ theme }) => ({
  '& .v5-MuiPaper-root': {
    padding: '40px 100px',
  },
  '& .v5-MuiDialogTitle-root': {
    justifyContent: 'normal',
    alignItems: 'center',
    color: theme.palette.primary.main,
  },
  '& .v5-MuiDialogActions-root': {
    width: 'calc(100% - 20px) !important',
  },
}));

interface DKTCustomDialogProps {
  open: boolean;
  onClose: () => Promise<void> | void;
  entity: BSSystem | Entity;
  kind: string;
  redirect?: boolean;
  operation: string;
  contentText: string;
  modalTitle: string;
  componentToUnlink?: string;
}

export const DKTCustomDialog = ({
  open,
  onClose,
  entity,
  kind,
  redirect,
  operation,
  contentText,
  modalTitle,
  componentToUnlink,
}: DKTCustomDialogProps) => {
  const config = useApi(configApiRef);
  const catalogApi = useApi(catalogApiRef);
  const navigate = useNavigate();
  const { setNotification } = useNotification();
  const { backstageFetch } = useBackstageApi();
  const deleteModal = operation === 'Delete';
  const unlinkModal = operation === 'Unlink';
  const updateModal = operation === 'Update';
  const successMessage = deleteModal
    ? `${kind} deleted successfully`
    : `${kind} updated successfully`;
  const method = deleteModal ? 'DELETE' : 'PUT';
  const [selectedComponent, setSelectedComponent] = useState<string>('');
  const [componentsList, setComponentsList] = useState<Entity[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchComponents = async () => {
      const { items } = await catalogApi.getEntities({
        filter: {
          kind: 'component',
        },
        fields: ['metadata.title', 'metadata.name'],
      });

      const entityComponents = entity?.relations
        ?.filter(relation => relation.type === 'hasPart')
        .map(relation => relation.targetRef.split('/')[1]);

      setComponentsList(
        items.filter(
          item =>
            !entityComponents?.includes(item.metadata.name) &&
            Array.isArray(entity.spec?.dependsOn) &&
            !entity.spec.dependsOn.includes(item.metadata.name),
        ),
      );
    };

    if (updateModal) {
      fetchComponents();
    }
  }, [updateModal]);

  async function submitRequest(data: any): Promise<BSSystem | void> {
    setLoading(true);
    try {
      await backstageFetch<BSSystem>(
        `${config.getString('backend.baseUrl')}/api/catalog/${kind}s/${
          entity.metadata.name
        }`,
        {
          method,
          body: data,
        },
      );
      setNotification({
        message: successMessage,
        severity: 'success',
      });

      if (redirect) {
        navigate('/catalog');
      } else {
        await onClose();
        setSelectedComponent('');
        setLoading(false);
      }
    } catch (error: any) {
      setNotification({
        message: error.message,
        severity: 'error',
      });
    }
  }

  async function updateSystem(entity: BSSystem) {
    await submitRequest({
      reference: entity.metadata.reference,
      name: entity.metadata.title,
      description: entity.metadata.description,
      support_group: entity.spec.supportGroup.split('/')[1],
      owner: entity.spec.owner.split('/')[1],
      backup_owner: entity.spec.backupOwner?.split('/')[1],
      lifecycle: entity.spec.lifecycle,
      domain: entity.spec.domain.split('/')[1],
      sub_domain: entity.spec.subdomain.split('/')[1],
      budget: entity.spec.budget,
      business_criticality_score: entity.spec.businessCriticalityScore,
      links: entity.spec.links,
      sources: entity.spec.sources,
      business_criticality_level: entity.spec.businessCriticalityLevel,
      component_dependencies: selectedComponent
        ? [...entity.spec.dependsOn, selectedComponent]
        : entity.spec.dependsOn.filter((d: string) => d !== componentToUnlink),
    });
  }

  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      aria-labelledby="dialog-register-apis"
      aria-describedby="dialog-register-apis-description"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle display="flex" justifyContent="space-between">
        <InfoOutlinedIcon sx={{ marginRight: '15px' }} />
        {modalTitle}
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={2} sx={{ paddingTop: '5px' }}>
          <Grid item xs={12}>
            <Typography sx={{ color: '#757575' }}>{contentText}</Typography>
          </Grid>
        </Grid>

        {updateModal && (
          <Autocomplete
            options={componentsList.map(component => component.metadata.title)}
            onChange={(_, newValue) => {
              const component = componentsList.find(
                component => component.metadata.title === newValue,
              );
              const componentName = component?.metadata.name;
              componentName && setSelectedComponent(componentName);
            }}
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
            renderInput={params => (
              <TextField
                {...params}
                label="Select a component"
                sx={{
                  width: '400px',
                  margin: '2rem',
                }}
              />
            )}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          loading={loading}
          color={updateModal ? 'primary' : 'error'}
          variant="contained"
          data-testid="delete-system"
          sx={{ marginLeft: '20px !important' }}
          onClick={() =>
            updateModal || unlinkModal
              ? updateSystem(entity as BSSystem)
              : submitRequest(entity)
          }
          disabled={loading}
        >
          {updateModal || unlinkModal ? 'Confirm' : 'Delete'}
        </LoadingButton>
      </DialogActions>
    </CustomDialog>
  );
};
