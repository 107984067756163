import {
  Alert,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Snackbar,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import { AlertMessage, configApiRef, useApi } from '@backstage/core-plugin-api';
import {
  BSGroup,
  ENTITY_TYPE,
  IDPBackReference,
  IDPSupportGroup,
  IDPUser,
} from '@internal/backstage-plugin-models-common';
import { DKTCustomCTA, useBackstageApi, useUser } from '@internal/common';
import {
  EntityMembersListCard,
  EntityOwnershipCard,
} from '@backstage/plugin-org';
import { catalogApiRef, useEntity } from '@backstage/plugin-catalog-react';
import { useEffect, useState } from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import EditOffIcon from '@mui/icons-material/EditOff';
import Grid from '@mui/material/Unstable_Grid2';
import GroupIcon from '@mui/icons-material/Group';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import PersonIcon from '@mui/icons-material/Person';
import { stringifyEntityRef } from '@backstage/catalog-model';
import { useNavigate } from 'react-router-dom';
import { Progress } from '@backstage/core-components';

export function CustomSupportGroupPage() {
  const navigate = useNavigate();
  const config = useApi(configApiRef);
  const { backstageFetch } = useBackstageApi();
  const { user, isAdmin } = useUser();
  const catalogApi = useApi(catalogApiRef);
  const { entity: supportGroupEntity } = useEntity();
  const [message, setMessage] = useState<AlertMessage>();

  const [subDomainEntity, setSubDomainEntity] = useState<BSGroup>();
  const [domainEntity, setDomainEntity] = useState<BSGroup>();
  const [initialSubDomainEntity, setInitialSubDomainEntity] =
    useState<BSGroup>();
  const [initialDomainEntity, setInitialDomainEntity] = useState<BSGroup>();
  const [initDomainToDo, setInitDomainToDo] = useState(false);
  const [owner, setOwner] = useState<IDPUser>();
  const [backupOwner, setBackupOwner] = useState<IDPUser>();

  const [allDomainEntities, setAllDomainEntities] = useState<BSGroup[]>([]);
  const [allSubDomainEntities, setAllSubDomainEntities] = useState<BSGroup[]>(
    [],
  );

  const [subDomainOptions, setSubDomainOptions] = useState<BSGroup[]>([]);

  const [mode, setMode] = useState<'read' | 'edit'>('read');

  const [isLoadingTimeout, setIsLoadingTimeout] = useState(true);

  const [isLoadingMembers, setIsLoadingMembers] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoadingTimeout(false);
    }, 300);
  }, []);

  useEffect(() => {
    loadSubDomainEntity();
    fetchIDPSupportGroup();
    synchroniseIDPSupportGroup();
  }, []);

  useEffect(() => {
    if (subDomainEntity && initDomainToDo) {
      loadDomainEntity();
    }
  }, [initDomainToDo]);

  useEffect(() => {
    if (mode === 'edit') {
      loadAllDomains();
      loadAllSubDomains();
    }
  }, [mode]);

  useEffect(() => {
    if (mode === 'edit' && domainEntity && allSubDomainEntities) {
      setSubDomainOptions(
        allSubDomainEntities?.filter(subDomain =>
          subDomain.relations?.find(
            relation =>
              relation.type === 'childOf' &&
              relation.targetRef.split('/')[1] === domainEntity.metadata.name,
          ),
        ),
      );
    } else {
      setSubDomainOptions([]);
    }
  }, [domainEntity, allSubDomainEntities]);

  const loadDomainEntity = async () => {
    const domainName = subDomainEntity?.relations
      ?.filter(relation => relation.type === 'childOf')
      .map(relation => relation.targetRef.split('/')[1])[0];
    if (domainName) {
      const domain = (await catalogApi.getEntityByRef(
        stringifyEntityRef({
          kind: 'group',
          name: domainName,
        }),
      )) as BSGroup;
      setDomainEntity(domain);
      setInitialDomainEntity(domain);
    }
    setInitDomainToDo(false);
  };

  const loadSubDomainEntity = async () => {
    const subDomainName = supportGroupEntity.relations
      ?.filter(relation => relation.type === 'childOf')
      .map(relation => relation.targetRef.split('/')[1])[0];
    if (subDomainName) {
      const subDomain = (await catalogApi.getEntityByRef(
        stringifyEntityRef({
          kind: 'group',
          name: subDomainName,
        }),
      )) as BSGroup;
      setSubDomainEntity(subDomain);
      setInitialSubDomainEntity(subDomain);
      setInitDomainToDo(true);
    }
  };

  const fetchIDPSupportGroup = async () => {
    try {
      const supportGroup = await backstageFetch<IDPSupportGroup>(
        `${config.getString('backend.baseUrl')}/api/catalog/support_groups/${
          supportGroupEntity.metadata.name
        }`,
        {
          method: 'GET',
        },
      );
      if (supportGroup) {
        setOwner(supportGroup.owner);
        setBackupOwner(supportGroup.backup_owner);
      } else {
        throw new Error('Failed to fetch users for selected support group');
      }
    } catch (error) {
      console.error('Error fetching support group users:', error);
    }
  };

  const synchroniseIDPSupportGroup = async () => {
    try {
      await backstageFetch<any>(
        `${config.getString('backend.baseUrl')}/api/catalog/support_groups/${
          supportGroupEntity.metadata.name
        }/synchronise`,
        {
          method: 'PATCH',
        },
      );
    } catch (error) {
      setMessage({
        message: 'Refresh of support group member in error',
        severity: 'error',
      });
      console.error(
        'Failed to synchronise users for selected support group:',
        error,
      );
    } finally {
      setTimeout(() => {
        setIsLoadingMembers(false);
      }, 2000);
    }
  };

  const loadAllDomains = async () => {
    const domainEntities = await catalogApi.getEntities({
      filter: [{ kind: 'group', 'spec.type': ENTITY_TYPE.DOMAIN }],
      fields: ['metadata.name', 'spec.profile.displayName'],
      order: { field: 'metadata.name', order: 'asc' },
    });
    setAllDomainEntities(domainEntities.items as BSGroup[]);
  };

  const loadAllSubDomains = async () => {
    const subDomainEntities = await catalogApi.getEntities({
      filter: [{ kind: 'group', 'spec.type': ENTITY_TYPE.SUB_DOMAIN }],
      fields: ['metadata.name', 'relations', 'spec.profile.displayName'],
      order: { field: 'metadata.name', order: 'asc' },
    });
    setAllSubDomainEntities(subDomainEntities.items as BSGroup[]);
  };

  function editAvailableForProfile() {
    return (
      owner?.uid.toUpperCase() === user?.uid.toUpperCase() ||
      backupOwner?.uid.toUpperCase() === user?.uid.toUpperCase() ||
      isAdmin
    );
  }

  async function patchToIdpBack() {
    setMessage({
      message: 'Updating support group organization',
      severity: 'info',
    });
    try {
      const idpBackReferences = await backstageFetch<IDPBackReference[]>(
        `${config.getString('backend.baseUrl')}/api/catalog/support_groups/${
          supportGroupEntity.metadata.name
        }`,
        {
          body: { sub_domain: subDomainEntity?.metadata.name },
          method: 'PATCH',
        },
      );
      if (idpBackReferences) {
        setMessage({
          severity: 'success',
          message: 'The support group have been updated',
        });
      }
    } catch (error: any) {
      setMessage({ severity: 'error', message: error.toString() });
      throw error;
    }
  }

  function getLabelWithLoader(
    label: string | undefined,
    labelIfUndefined: string,
  ) {
    return isLoadingTimeout ? 'Loading...' : label ?? labelIfUndefined;
  }

  function subDomainNameHasChange() {
    return (
      subDomainEntity &&
      subDomainEntity?.metadata.name !== initialSubDomainEntity?.metadata.name
    );
  }

  function noMoreSubDomainOnAnEmptySupportGroup() {
    return (
      subDomainEntity === undefined &&
      domainEntity === undefined &&
      initialSubDomainEntity !== undefined &&
      supportGroupEntity.relations?.filter(
        relation => relation.type === 'ownerOf',
      ).length === 0
    );
  }

  return (
    <>
      <Grid xs={12}>
        <DKTCustomCTA kind="group" />
      </Grid>
      <Grid xs={12} md={6}>
        <Card>
          <CardHeader
            sx={{
              paddingBottom: '20px',
            }}
            title="About"
            action={
              <>
                <Tooltip title="Update the support group organization (only available for owner or backup owner)">
                  {editAvailableForProfile() ? (
                    <ModeEditIcon
                      data-testid="edit-button"
                      sx={{
                        cursor: 'pointer',
                        marginTop: '15px',
                        marginRight: '15px',
                      }}
                      onClick={() => {
                        setMode('edit');
                      }}
                    />
                  ) : (
                    <EditOffIcon
                      sx={{
                        marginTop: '15px',
                        marginRight: '15px',
                      }}
                    />
                  )}
                </Tooltip>
              </>
            }
          />
          <Divider />
          <CardContent>
            <Grid
              container
              spacing={3}
              sx={{ width: '100%' }}
              paddingTop={1}
              width={1000}
            >
              <Stack>
                <Stack
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    marginBottom: '10px',
                  }}
                  direction="row"
                  useFlexGap
                  flexWrap="wrap"
                >
                  {mode === 'edit' && allDomainEntities.length > 0 ? (
                    <Autocomplete
                      data-testid={`domain-autocomplete`}
                      sx={{ width: '100%' }}
                      value={domainEntity}
                      options={allDomainEntities}
                      getOptionLabel={option => option.spec.profile.displayName}
                      isOptionEqualToValue={(option, value) => option === value}
                      onChange={(_event, value) => {
                        setDomainEntity(value ?? undefined);
                        setSubDomainEntity(undefined);
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          required={true}
                          variant="outlined"
                          label={'Domain'}
                          placeholder={'Domain'}
                        />
                      )}
                    />
                  ) : (
                    <>
                      <Grid xs={4} sx={{ width: '165px' }}>
                        <Stack spacing={2} direction="row">
                          <GroupIcon />
                          <div>Domain</div>
                        </Stack>
                      </Grid>
                      <Chip
                        data-testid={'domain-chip'}
                        label={getLabelWithLoader(
                          domainEntity?.spec.profile.displayName,
                          'No domain associated',
                        )}
                        disabled={domainEntity === undefined}
                        sx={{
                          textTransform: 'capitalize',
                          backgroundColor: '#FFECD0!important',
                          borderRadius: '100px!important',
                          color: '#FF9800!important',
                          height: '30px!important',
                          paddingInline: '10px',
                        }}
                        onClick={() =>
                          navigate(
                            `/catalog/default/group/${domainEntity?.metadata.name}`,
                          )
                        }
                      />
                    </>
                  )}
                </Stack>
                <Stack
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    marginBottom: '10px',
                  }}
                  direction="row"
                  useFlexGap
                  flexWrap="wrap"
                >
                  {mode === 'edit' && subDomainOptions.length > 0 ? (
                    <Autocomplete
                      data-testid={`subDomain-autocomplete`}
                      sx={{ width: '100%' }}
                      value={subDomainEntity}
                      options={subDomainOptions}
                      getOptionLabel={option => option.spec.profile.displayName}
                      disabled={
                        !subDomainOptions || subDomainOptions?.length === 0
                      }
                      isOptionEqualToValue={(option, value) => option === value}
                      onChange={(_event, value) => {
                        setSubDomainEntity(value ?? undefined);
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          required={true}
                          variant="outlined"
                          label={'Sub Domain'}
                          placeholder={'Sub Domain'}
                        />
                      )}
                    />
                  ) : (
                    <>
                      <Grid xs={4} sx={{ width: '165px' }}>
                        <Stack spacing={2} direction="row">
                          <GroupIcon />
                          <div>Sub Domain</div>
                        </Stack>
                      </Grid>
                      <Chip
                        data-testid={'subDomain-chip'}
                        label={getLabelWithLoader(
                          subDomainEntity?.spec.profile.displayName,
                          'No sub domain associated',
                        )}
                        disabled={subDomainEntity === undefined}
                        sx={{
                          textTransform: 'capitalize',
                          backgroundColor: '#E1E3F5!important',
                          borderRadius: '100px!important',
                          color: '#2E77D0!important',
                          height: '30px!important',
                          paddingInline: '10px',
                        }}
                        onClick={() =>
                          navigate(
                            `/catalog/default/group/${subDomainEntity?.metadata.name}`,
                          )
                        }
                      />
                    </>
                  )}
                </Stack>
                <Stack
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    marginBottom: '10px',
                  }}
                  direction="row"
                  useFlexGap
                  flexWrap="wrap"
                >
                  <Grid xs={4} sx={{ width: '165px' }}>
                    <Stack spacing={2} direction="row">
                      <PersonIcon />
                      <div>Owner</div>
                    </Stack>
                  </Grid>
                  <Chip
                    data-testid={'owner-chip'}
                    label={getLabelWithLoader(
                      owner?.full_name,
                      'No owner associated',
                    )}
                    disabled={owner === undefined}
                    sx={{
                      textTransform: 'capitalize',
                      backgroundColor: '#EEEEEE!important',
                      borderRadius: '100px!important',
                      color: '#757575!important',
                      height: '30px!important',
                      paddingInline: '10px',
                    }}
                    onClick={() =>
                      navigate(`/catalog/default/user/${owner?.uuid}`)
                    }
                  />
                </Stack>
                <Stack
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    marginBottom: '10px',
                  }}
                  direction="row"
                  useFlexGap
                  flexWrap="wrap"
                >
                  <Grid xs={4} sx={{ width: '165px' }}>
                    <Stack spacing={2} direction="row">
                      <PersonIcon />
                      <div>Backup Owner</div>
                    </Stack>
                  </Grid>
                  <Chip
                    data-testid={'backupOwner-chip'}
                    label={getLabelWithLoader(
                      backupOwner?.full_name,
                      'No backup owner associated',
                    )}
                    disabled={backupOwner === undefined}
                    sx={{
                      textTransform: 'capitalize',
                      backgroundColor: '#EEEEEE!important',
                      borderRadius: '100px!important',
                      color: '#757575!important',
                      height: '30px!important',
                      paddingInline: '10px',
                    }}
                    onClick={() =>
                      navigate(`/catalog/default/user/${backupOwner?.uuid}`)
                    }
                  />
                </Stack>
              </Stack>
            </Grid>
          </CardContent>
          <CardActions>
            {mode === 'edit' && (
              <>
                <Button
                  variant="outlined"
                  type="submit"
                  disabled={false}
                  disableElevation
                  onClick={() => {
                    setDomainEntity(initialDomainEntity);
                    setSubDomainEntity(initialSubDomainEntity);
                    setMode('read');
                  }}
                >
                  Cancel
                </Button>
                <Tooltip
                  title={
                    domainEntity === undefined &&
                    subDomainEntity === undefined &&
                    !noMoreSubDomainOnAnEmptySupportGroup() &&
                    'You have to unlink your systems/components from the support group'
                  }
                >
                  <span>
                    <Button
                      variant="contained"
                      type="submit"
                      disabled={
                        !(
                          subDomainNameHasChange() ||
                          noMoreSubDomainOnAnEmptySupportGroup()
                        )
                      }
                      disableElevation
                      onClick={() => {
                        patchToIdpBack();
                        setInitialDomainEntity(domainEntity);
                        setInitialSubDomainEntity(subDomainEntity);
                        setMode('read');
                      }}
                    >
                      Save
                    </Button>
                  </span>
                </Tooltip>
              </>
            )}
          </CardActions>
        </Card>
      </Grid>
      <Grid xs={12} md={6}>
        <EntityOwnershipCard
          variant="gridItem"
          relationsType="aggregated"
          entityLimit={10}
          hideRelationsToggle={true}
        />
      </Grid>
      <Grid xs={12} md={12}>
        {isLoadingMembers ? <Progress /> : <EntityMembersListCard />}
      </Grid>
      {message && (
        <Snackbar
          role="alert"
          open={!!message}
          autoHideDuration={6000}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          onClose={() => setMessage(undefined)}
        >
          <Alert
            onClose={() => setMessage(undefined)}
            severity={message?.severity ?? 'info'}
            variant="filled"
            sx={{ width: '100%' }}
          >
            {message?.message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}
