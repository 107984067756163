import {
  BSSystem,
  DistributionSource,
} from '@internal/backstage-plugin-models-common';
import { FC, useCallback, useMemo } from 'react';
import { PiePlot, pieArcLabelClasses } from '@mui/x-charts/PieChart';

import { Box } from '@mui/material';
import { DataType } from './Datatype.interface';
import { EntityFilterQuery } from '@backstage/catalog-client';
import Grid2 from '@mui/material/Unstable_Grid2';
import { InfoCard } from '@backstage/core-components';
import { ResponsiveChartContainer } from '@mui/x-charts/ResponsiveChartContainer';
import { useBSSystems } from '@internal/common';

export interface Props {
  filter: EntityFilterQuery;
  source: DistributionSource;
  title: string;
}

export const DistributionChart: FC<Props> = ({ filter, source, title }) => {
  const size = { height: 500 };

  const { systems } = useBSSystems(filter);

  // Memoized dataset computation function
  const computeDatasets = useCallback(
    (systems: BSSystem[]): DataType[] => {
      if (!systems?.length) return [];

      const datasetMap: Record<string, DataType> = {};

      systems.forEach(system => {
        const level =
          (system.spec?.businessCriticalityLevel as string) || 'N/A';
        const value =
          source === DistributionSource.budget ? system.spec?.budget ?? 0 : 1;

        if (!datasetMap[level]) {
          datasetMap[level] = { value, label: level, fullLabel: level };
        } else {
          datasetMap[level].value += value;
        }
      });

      return Object.values(datasetMap)
        .filter(value => value.value > 0)
        .sort((a, b) => b.value - a.value);
    },
    [source],
  );
  const datasets = useMemo(
    () => computeDatasets(systems),
    [systems, computeDatasets],
  );

  return (
    <InfoCard title={title}>
      <Grid2>
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
          {datasets.map((dataset, index) => (
            <Box
              key={dataset.label}
              sx={{
                display: 'flex',
                alignItems: 'center',
                maxWidth: '200px',
                mx: '1rem',
                my: '.5rem',
              }}
            >
              <div
                style={{
                  width: '20px',
                  height: '20px',
                  borderRadius: '100%',
                  backgroundColor: [
                    '#3643BA',
                    '#36BA5A',
                    '#BA9D36',
                    '#BA3639',
                    '#3F4365',
                  ][index % 5],
                  marginRight: '5px',
                }}
              ></div>
              <div>{dataset.label}</div>
            </Box>
          ))}
        </Box>

        {datasets.length ? (
          <ResponsiveChartContainer
            colors={['#3643BA', '#36BA5A', '#BA9D36', '#BA3639', '#3F4365']}
            series={[
              {
                type: 'pie',
                arcLabel: item =>
                  new Intl.NumberFormat('en-US', {
                    maximumSignificantDigits: 3,
                  }).format(item.value),
                arcLabelMinAngle: 15,
                innerRadius: 50,
                cornerRadius: 5,
                paddingAngle: 2,
                highlightScope: { fade: 'global', highlight: 'item' },
                faded: {
                  innerRadius: 35,
                  additionalRadius: -30,
                  color: '#ddd',
                },
                data: datasets.map(e => ({ label: e.label, value: e.value })),
                valueFormatter: v =>
                  source === DistributionSource.budget
                    ? new Intl.NumberFormat('fr-FR', {
                        style: 'currency',
                        currency: 'EUR',
                      }).format(v.value)
                    : new Intl.NumberFormat('en-US', {
                        maximumSignificantDigits: 3,
                      }).format(v.value),
              },
            ]}
            sx={{
              [`& .${pieArcLabelClasses.root}`]: {
                fontSize: '15px',
                fontWeight: 'bold',
                fill: '#fff',
              },
            }}
            {...size}
          >
            <PiePlot />
          </ResponsiveChartContainer>
        ) : (
          'No data to display'
        )}
      </Grid2>
    </InfoCard>
  );
};
