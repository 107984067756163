import { useEffect, useState } from 'react';
import { OpenapiResult } from '@internal/backstage-plugin-models-common';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { useBackstageApi } from './use-backstage-api';

export const useOpenapi = (graviteeId: string) => {
  const { backstageFetch } = useBackstageApi();
  const config = useApi(configApiRef);
  const [error, setError] = useState<Error | null>(null);
  const [openapis, setOpenapis] = useState<OpenapiResult[]>([]);

  useEffect(() => {
    getOpenapi();
  }, [graviteeId]);

  const getOpenapi = async () => {
    try {
      const openapis = await backstageFetch<
        OpenapiResult[] | { error: string; error_description: string }
      >(
        `${config.getString(
          'backend.baseUrl',
        )}/api/quapi/apis/${graviteeId}/openapis`,
        {
          method: 'GET',
        },
      );
      if ('error' in openapis) {
        setError(new Error(openapis.error_description));
      }
      setOpenapis(openapis as OpenapiResult[]);
    } catch (error) {
      setError(error as Error);
    }
  };

  return { openapis, error };
};
