import { DKTCustomCTA, DKTEntityLayout } from '@internal/common';
import {
  EntityOwnershipCard,
  EntityUserProfileCard,
} from '@backstage/plugin-org';

import { EntityLayout } from '@backstage/plugin-catalog';
import Grid from '@mui/material/Unstable_Grid2';
import { entityWarningContent } from '../common/EntityWarningContent';

export const userPage = (
  <DKTEntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        <Grid xs={12}>
          <DKTCustomCTA kind="user" />
        </Grid>
        <Grid xs={12} md={6}>
          <EntityUserProfileCard variant="gridItem" />
        </Grid>
        <Grid xs={12} md={6}>
          <EntityOwnershipCard
            variant="gridItem"
            relationsType="aggregated"
            entityLimit={10}
            hideRelationsToggle={true}
          />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </DKTEntityLayout>
);
