import { Box, Chip, ListItemText, Typography } from '@mui/material';

import { FC } from 'react';
import { Link } from '@backstage/core-components';
import { Result } from '@backstage/plugin-search-common';
import { makeStyles } from '@mui/styles';

/** @public */
export type CatalogSearchResultListItemClassKey =
  | 'item'
  | 'flexContainer'
  | 'itemText';

const useStyles = makeStyles(
  {
    item: {
      display: 'flex',
    },
    flexContainer: {
      flexWrap: 'wrap',
    },
    itemText: {
      width: '100%',
      wordBreak: 'break-all',
      marginBottom: '1rem',
    },
    noDescription: {
      color: '#ccc',
    },
  },
  { name: 'CatalogSearchResultListItem' },
);

export interface CatalogSearchResultListItemProps {
  result?: Result<any>;
  lineClamp?: number;
  onNavigate?: () => void;
}

export const SearchResultItem: FC<CatalogSearchResultListItemProps> = ({
  result,
  lineClamp,
  onNavigate,
}) => {
  const classes = useStyles();
  return result ? (
    <Box
      sx={{
        overflow: 'hidden',
        fontSize: '.75rem',
        fontWeight: 'bold',
        justifyContent: 'start',
        alignItems: 'start',
        borderRadius: '10px',
        bgcolor: 'white',
        margin: '5px',
        border: '1px solid #3643ba10',
        paddingTop: '10px',
        padding: '10px',
        display: 'flex',
        justifyItems: 'start',
      }}
    >
      <ListItemText
        className={classes.itemText}
        primaryTypographyProps={{ variant: 'h6' }}
        primary={
          <Link onClick={onNavigate} noTrack to={result.document.location}>
            {result.document.title && result.document.title !== ''
              ? result.document.title
              : result.document.name}
          </Link>
        }
        secondary={
          <Typography
            component="span"
            style={{
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: lineClamp,
              overflow: 'hidden',
            }}
            color="textSecondary"
            variant="body2"
          >
            {result.document.text && result.document.text !== '' ? (
              result.document.text
            ) : (
              <span className={classes.noDescription}>
                No description provided
              </span>
            )}
          </Typography>
        }
      />
      <Box>
        {result.document.kind && (
          <Chip
            sx={{
              paddingY: '2px',
              paddingX: '10px',
              margin: '2px',
              backgroundColor: '#3643ba',
              color: 'white',
            }}
            label={result.document.kind}
            size="small"
          />
        )}
      </Box>
    </Box>
  ) : null;
};
