import { Content, Progress, RoutedTabs } from '@backstage/core-components';
import {
  EntityDisplayName,
  useAsyncEntity,
} from '@backstage/plugin-catalog-react';
import {
  attachComponentData,
  createExternalRouteRef,
  createRouteRef,
  useElementFilter,
} from '@backstage/core-plugin-api';

import { Alert } from '@mui/material';
import { Entity } from '@backstage/catalog-model';
import { Header } from '../Header/Header';
import { TabProps } from '@material-ui/core';

export const unregisterRedirectRouteRef = createExternalRouteRef({
  id: 'catalog:unregister-redirect',
  optional: true,
});

export const rootRouteRef = createRouteRef({
  id: 'catalog',
});

export type DKTEntityLayoutRouteProps = {
  path: string;
  title: string;
  children: JSX.Element;
  if?: (entity: Entity) => boolean;
  tabProps?: TabProps<React.ElementType, { component?: React.ElementType }>;
};

export interface DKTEntityLayoutProps {
  children?: React.ReactNode;
}

const dataKey = 'plugin.catalog.entityLayoutRoute';
const Route: (props: DKTEntityLayoutRouteProps) => null = () => null;
attachComponentData(Route, dataKey, true);
attachComponentData(Route, 'core.gatherMountPoints', true); // This causes all mount points that are discovered within this route to use the path of the route itself

export const DKTEntityLayout = (props: DKTEntityLayoutProps) => {
  const { children } = props;
  const { entity, loading, error } = useAsyncEntity();
  const routes = useElementFilter(
    children,
    elements =>
      elements
        .selectByComponentData({
          key: dataKey,
          withStrictError:
            'Child of EntityLayout must be an EntityLayout.Route',
        })
        .getElements<DKTEntityLayoutRouteProps>() // all nodes, element data, maintain structure or not?
        .flatMap(({ props: elementProps }) => {
          if (!entity || (elementProps.if && !elementProps.if(entity))) {
            return [];
          }

          return [
            {
              path: elementProps.path,
              title: elementProps.title,
              children: elementProps.children,
              tabProps: elementProps.tabProps,
            },
          ];
        }),
    [entity],
  );

  return (
    <div>
      <Header
        title={
          entity ? <EntityDisplayName entityRef={entity} hideIcon /> : null
        }
        subtitle={entity?.kind}
      />

      {loading && <Progress />}

      {entity && <RoutedTabs routes={routes} />}

      {error && (
        <Content>
          <Alert severity="error">{error.toString()}</Alert>
        </Content>
      )}

      {!loading && !error && !entity && <Content>NotFoundComponent</Content>}
    </div>
  );
};
