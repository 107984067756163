export { customSystemOverviewPlugin, CustomSystemOverviewPage } from './plugin';
export { CustomSystemAboutCard } from './components/CustomAboutCard/CustomSystemAboutCard';
export { CustomComponentCard } from './components/CustomComponentCard/CustomComponentCard';
export { CustomLinkedComponentCard } from './components/CustomComponentCard/CustomLinkedComponentCard';
export { TechPerformanceCard } from './components/TechPerformanceCard/TechPerformanceCard';

export const iconList = [
  'slack',
  'github',
  'pagerduty',
  'statuspal',
  'gravitee',
  'dynatrace',
  'datadog',
  'jira',
  'wiki',
  'smax',
  'other',
];

export type IconKey =
  | 'slack'
  | 'github'
  | 'pagerduty'
  | 'statuspal'
  | 'docapi'
  | 'gravitee'
  | 'dynatrace'
  | 'datadog'
  | 'quapi'
  | 'jira'
  | 'other'
  | 'wiki';
