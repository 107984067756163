'use client';

import { useApi, configApiRef } from '@backstage/core-plugin-api';
import { datadogRum } from '@datadog/browser-rum';
import { useEffect } from 'react';
import { useUser } from '../../hooks';

export const DatadogRumComponent = () => {
  const config = useApi(configApiRef);
  const { isAdmin, user } = useUser();

  useEffect(() => {
    if (user) {
      if (!isAdmin) {
        datadogRum.setUser({ id: user?.uuid });
        datadogRum.startSessionReplayRecording();
      }
    }
  }, [user]);

  useEffect(() => {
    datadogRum.init({
      applicationId: config.getString('datadogRum.applicationId'),
      clientToken: config.getString('datadogRum.clientToken'),
      site: 'datadoghq.eu',
      service: 'idp-backstage',
      env: config.getString('datadogRum.env'),
      // Specify a version number to identify the deployed version of your application in Datadog
      // version: '1.0.0',
      sessionSampleRate: config.getNumber('datadogRum.sessionSampleRate'),
      sessionReplaySampleRate: config.getNumber(
        'datadogRum.sessionReplaySampleRate',
      ),
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      startSessionReplayRecordingManually: true,
      defaultPrivacyLevel: 'allow',
    });
  }, []);

  return <></>;
};
