import { ApiTypeTitle } from '@backstage/plugin-api-docs';

import { ApiEntity } from '@backstage/catalog-model';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import { EntityTable } from '@backstage/plugin-catalog-react';
import { TableColumn } from '@backstage/core-components';
import { Button } from '@mui/material';
import { useState } from 'react';
import { ApiDefinitionDialog } from './ApiDefinitionDialog';

export function createSpecApiTypeColumn(): TableColumn<ApiEntity> {
  return {
    title: 'Type',
    field: 'spec.type',
    render: entity => <ApiTypeTitle apiEntity={entity} />,
  };
}

export const ApiDefinitionButton = ({
  apiEntity,
}: {
  apiEntity: ApiEntity;
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <>
      <Button aria-label="delete" onClick={() => setDialogOpen(!dialogOpen)}>
        <WysiwygIcon />
      </Button>
      <ApiDefinitionDialog
        entity={apiEntity}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      />
    </>
  );
};

export function createApiDefinitionColumn(): TableColumn<ApiEntity> {
  return {
    title: 'API Definition',
    render: entity => <ApiDefinitionButton apiEntity={entity} />,
  };
}

export const apiEntityColumns: TableColumn<ApiEntity>[] = [
  EntityTable.columns.createEntityRefColumn({ defaultKind: 'API' }),
  EntityTable.columns.createOwnerColumn(),
  createSpecApiTypeColumn(),
  EntityTable.columns.createSpecLifecycleColumn(),
  EntityTable.columns.createMetadataDescriptionColumn(),
  createApiDefinitionColumn(),
];
