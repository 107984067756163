import { CardContent, Stack } from '@mui/material';
import { EntityDisplayName, useEntity } from '@backstage/plugin-catalog-react';
import { useEffect, useState } from 'react';

import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Unstable_Grid2';
import GroupIcon from '@mui/icons-material/Group';
import { InfoCard } from '@backstage/core-components';
import PersonIcon from '@mui/icons-material/Person';
import { useNavigate } from 'react-router-dom';
import { ENTITY_TYPE } from '@internal/backstage-plugin-models-common';

export function DomainSubDomainAboutPage() {
  const { entity: group } = useEntity();
  const navigate = useNavigate();
  const children = group?.spec?.children as string[];
  const parent = group.relations
    ?.filter(relation => relation.type === 'childOf')
    .map(relation => relation.targetRef.split('/')[1])[0];

  const [fatherLabel, setFatherLabel] = useState<string>();
  const [childrenLabel, setChildrenLabel] = useState<string>();

  useEffect(() => {
    switch (group?.spec?.type) {
      case ENTITY_TYPE.COMPANY:
        setFatherLabel(undefined);
        setChildrenLabel('Domain(s)');
        break;
      case ENTITY_TYPE.DOMAIN:
        setFatherLabel('Company');
        setChildrenLabel('Subdomain(s)');
        break;
      case ENTITY_TYPE.SUB_DOMAIN:
        setFatherLabel('Domain');
        setChildrenLabel('Support Group(s)');
        break;
      default:
        break;
    }
  }, [group?.spec?.type]);

  return (
    <InfoCard title="About">
      <CardContent>
        <Grid container spacing={3}>
          <Stack>
            {parent && (
              <Stack
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                }}
                direction="row"
                useFlexGap
                flexWrap="wrap"
              >
                <Grid xs={4} sx={{ width: '165px' }}>
                  <Stack spacing={2} direction="row">
                    <PersonIcon />
                    <div>{fatherLabel}</div>
                  </Stack>
                </Grid>
                <Chip
                  label={
                    <EntityDisplayName
                      hideIcon={true}
                      entityRef={{
                        name: parent,
                        kind: 'Group',
                        namespace: 'default',
                      }}
                    />
                  }
                  sx={{
                    textTransform: 'capitalize',
                    backgroundColor: '#FFECD0!important',
                    borderRadius: '100px!important',
                    color: '#FF9800!important',
                    height: '30px!important',
                    margin: '5px!important',
                    padding: '10px!important',
                  }}
                  onClick={() => navigate(`/catalog/default/group/${parent}`)}
                />
              </Stack>
            )}
            <Stack
              sx={{ alignItems: 'center', display: 'flex' }}
              direction="row"
              useFlexGap
              flexWrap="wrap"
            >
              <Grid xs={4} sx={{ width: '165px' }}>
                <Stack spacing={2} direction="row">
                  <GroupIcon />
                  <div>{childrenLabel}</div>
                </Stack>
              </Grid>
              {children?.map((child: string) => (
                <Chip
                  key={child}
                  label={
                    <EntityDisplayName
                      hideIcon={true}
                      entityRef={{
                        name: child,
                        kind: 'Group',
                        namespace: 'default',
                      }}
                    />
                  }
                  onClick={() => navigate(`/catalog/default/group/${child}`)}
                  sx={{
                    backgroundColor: '#E1E3F5!important',
                    borderRadius: '100px!important',
                    color: '#2E77D0!important',
                    height: '30px!important',
                    margin: '5px!important',
                    padding: '10px!important',
                  }}
                />
              ))}
            </Stack>
          </Stack>
        </Grid>
      </CardContent>
    </InfoCard>
  );
}
